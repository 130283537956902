<script setup lang="ts">
import { type OtpProps } from "./protocols";

const props = defineProps<OtpProps>();
const emit = defineEmits(["change", "submit"]);

const otpFieldsRef = ref<HTMLInputElement[]>([]);

function setItemRef(el: HTMLInputElement) {
  if (el && !otpFieldsRef.value.includes(el)) {
    otpFieldsRef.value.push(el);
  }
}

function handleOtp(event: KeyboardEvent) {
  const input = event.target as HTMLInputElement;
  const value = input.value;
  const isValidInput = value.match(/[0-9a-z]/gi);
  input.value = "";
  input.value = isValidInput ? value[0] : "";

  const fieldIndex = Number(input.dataset.index);

  if (fieldIndex < otpFieldsRef.value.length - 1 && isValidInput) {
    (input.nextElementSibling as HTMLInputElement).focus();
    emit("change", getValue());
  }

  if (event.key === "Backspace" && fieldIndex > 0) {
    (input.previousElementSibling as HTMLInputElement).focus();
    emit("change", getValue());
  }

  if (fieldIndex === otpFieldsRef.value.length - 1 && isValidInput) {
    emit("change", getValue());
    emit("submit", getValue());
  }
}

const handleOnPasteOtp = (event: ClipboardEvent) => {
  const data = event.clipboardData!.getData("text");
  const value = data.split("");
  if (value.length === otpFieldsRef.value.length) {
    otpFieldsRef.value.forEach((input, index) => (input.value = value[index]));
    emit("submit", getValue());
  }
};

function getValue() {
  let otpValue = "";
  otpFieldsRef.value.forEach((otp) => {
    otpValue += otp.value;
  });

  return otpValue;
}

onMounted(() => {
  if (otpFieldsRef.value.length > 0) {
    otpFieldsRef.value.forEach((otp, index) => {
      otp.dataset.index = index.toString();
      otp.addEventListener("keyup", handleOtp);
      otp.addEventListener("paste", handleOnPasteOtp);
    });
  }
});
</script>
<template>
  <div
    className="flex flex-col relative items-center justify-center"
    data-testid="otp"
  >
    <div class="flex gap-x-3">
      <input
        v-for="item in Array.from({ length: props.size }, (v, k) => k)"
        :ref="setItemRef as any"
        :key="item"
        :data-testid="`otp-${item}`"
        type="number"
        maxLength="1"
        class="p-3 outline-none focus:ring-4 ring-primary-400 otp-input"
        :class="{
          'ring-4 ring-red-600': $props.errormessage,
          'ring-p-yellow ring-4': $props.loading,
          'bg-transparent text-seashell': $props.dark,
          'w-16 h-24 rounded-2xl border border-gray-400 text-4xl text-center':
            $props.fdi,
          'w-10 h-12 rounded border text-base font-bold': !$props.fdi,
        }"
      />
    </div>
    <span v-if="errormessage" class="text-xs text-red-600 mt-2">{{
      errormessage
    }}</span>
  </div>
</template>

<style scoped>
.otp-input {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}
</style>
